<template>
  <div>
    <div class="detail">
      <div class="row">
        <div class="col-sm-2">
          <img :src="feu.imgfeu" />
        </div>
        <div class="col-sm-1">
          <a href="#" @click="modifFeu()"><img :src="sta(feu.statut)" data-toggle="tooltip" data-placement="top" :title="alt(feu.statut)"/></a>
        </div>
        <div class="col-sm-7">
          Equipement : {{ feu.nomfeu }} ({{ feu.encelv }})<br />
          {{ feu.typefeu }} {{ feu.serigraphie }}<br />
          Régulateur : {{ feu.nomreg }}<br />
          Couleur : <img :src="svg(feu.strcode)" /> {{ feu.couleur }}<br />
        </div>
        <div class="col-sm-2">
          <a :href="doc(feu.docfeu)" target="_blank"
            ><img src="../assets/img/pdf.png" data-toggle="tooltip" data-placement="top" title="Voir la notice de l'équipement" /></a>
          <br />
          <a href="#" @click="retour()"
            ><img src="../assets/img/return.png" data-toggle="tooltip" data-placement="top" title="Retourner sur le plan"
          /></a>
        </div>
      </div>
    </div>

    <table class="table">
      <thead v-if="countm > 0">
        <tr>
          <th colspan="4"><h2>Photométrie</h2></th>
        </tr>
      </thead>
      <thead v-if="countm > 0">
        <tr>
          <th scope="col"><i class="fa fa-calendar"></i> Date</th>
          <th colspan="3" scope="col"><i class="fa fa-comment"></i> Mesure</th>
        </tr>
      </thead>
      <tbody v-if="countm > 0">
        <tr v-for="mesure in mesures" :key="mesure.id">
          <td with="100">{{ mesure.dt10 | sdt }}</td>
          <td colspan="3" >
            <span :class="couleur(mesure)"
              >{{ mesure.candela }} Cd (min: {{ mesure.cdminimal }} Cd) (nom:
              {{ mesure.cdnominal }} Cd) (maint: {{ mesure.cdmaintenance }} Cd)
              {{ action(mesure) }}</span
            >
          </td>
        </tr>
      </tbody>

      <thead>
        <tr>
          <th colspan="4">
            <h2>
              Actions
              <span v-if="menuAdmin"><button @click="addAction()" data-toggle="tooltip" data-placement="top" title="Ajouter une action"><i class="fa fa-plus-square"></i></button></span>
            </h2>
          </th>
        </tr>
      </thead>
      <thead>
        <tr>
          <th scope="col"><i class="fa fa-calendar"></i> Date</th>
          <th scope="col"><i class="fa fa-comment"></i> Action</th>
          <th scope="col"><i class="fa fa-bolt"></i> Régulateur</th>
          <th scope="col"><i class="fa fa-chevron-circle-right"></i> Fonction</th>
          <th scope="col"><i class="fa fa-chevron-circle-up"></i> Enc/Elv</th>
          <th scope="col"><i class="fa fa-edit"></i> Modifier</th>
          <th scope="col"><i class="fa fa-folder-open-o"></i> En Cours</th>
        </tr>
      </thead>
      <tbody>
        <tr :key="action.id" v-for="action in actions">
          <td with="100">{{ action.dtaction | sdt }}</td>
          <td>{{ action.Sujet }}</td>
          <td>{{ action.nomregulateur }}</td>
          <td>{{ action.nomfonction }}</td>
          <td>{{ action.encelv }}</td>
          <td>
            <span v-if="menuAdmin"><a href="#" @click="editAction(action.id)"><i class="fa fa-edit"></i></a></span>
          </td>
          <td>
            <span v-if="action.solde==0"><i class="fa fa-folder-open-o"></i></span><span v-if="action.solde!=0"><i class="fa fa-folder"></i></span>
          </td>
        </tr>
      </tbody>

      <thead>
        <tr>
          <th colspan="4">
            <h2>
              Interventions
              <span v-if="menuAdmin"><button @click="addInter()" data-toggle="tooltip" data-placement="top" title="Ajouter une intervention"><i class="fa fa-plus-square"></i></button></span>
            </h2>
          </th>
        </tr>
      </thead>
      <thead>
        <tr>
          <th scope="col"><i class="fa fa-calendar"></i> Date</th>
          <th scope="col"><i class="fa fa-comment"></i> Intervention</th>
          <th scope="col"><i class="fa fa-bolt"></i> Régulateur</th>
          <th scope="col"><i class="fa fa-chevron-circle-right"></i> Fonction</th>
          <th scope="col"><i class="fa fa-chevron-circle-up"></i> Enc/Elv</th>
          <th scope="col"><i class="fa fa-edit"></i> Modifier</th>
        </tr>
      </thead>
      <tbody>
        <tr :key="intervention.id" v-for="intervention in interventions">
          <td with="100">{{ intervention.dtaction | sdt }}</td>
          <td>{{ intervention.Sujet }}</td>
          <td>{{ intervention.nomregulateur }}</td>
          <td>{{ intervention.nomfonction }}</td>
          <td>{{ intervention.encelv }}</td>
          <td colspan="2">
            <span v-if="menuAdmin"><a href="#" @click="editInter(intervention.id)"><i class="fa fa-edit"></i></a></span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import store from "../store";
import { getPrefix, executeApi, selectApi } from "../lib/api.js";
import { getPath } from "../lib/svg.js";
import { calcAttenuation } from "../lib/fct.js";
import Vue from "vue";

export default {
  name: "Feu",
  store: store,
  data() {
    return {
      id: this.$route.params.id,
      counta: 0,
      actions: [],
      counti: 0,
      interventions: [],
      feu: {},
      countm: 0,
      mesures: [],
    };
  },
  computed: {
		menuAdmin: function () {
			return (store.state.typeUser == 2)
		}
  },
  filters: {
    sdt: function (dt) {
      if (!dt) return "";
      return (
        dt.substring(8, 10) +
        "/" +
        dt.substring(5, 7) +
        "/" +
        dt.substring(0, 4)
      );
    },
  },
  methods: {
    modifFeu() {
      let statut = this.feu.statut == 0 ? 1 : 0;

      let sql = "UPDATE feu SET statut=" + statut + " WHERE id=" + this.id;
        executeApi(this, sql).then(
          (response) => {
            this.feu.statut = statut;
            if (statut == 0) this.addInter(); else this.addAction();
            },
          (response) => {
          })

    },
    addAction() {
      this.$router.push({ path: "/addaction/0/" + this.id + "/0" });
    },
    addInter() {
      this.$router.push({ path: "/addinter/0/" + this.id + "/0" });
    },
    editAction(id) {
      this.$router.push({ path: "/addaction/" + id + "/" + this.id + "/0" });
    },
    editInter(id) {
      this.$router.push({ path: "/addinter/" + id + "/" + this.id + "/0" });
    },
    retour() {
      this.$router.push({ path: "/maps" });
    },
    doc(code) {
      let ret = getPrefix() + "/doc/feux/" + code;
      return ret;
    },
    svg(code) {
      let path = getPath(code, 0, false);
      return path;
    },
    sta(statut) {
      return  getPrefix() + "/img/" + (statut == 0 ? "OK.png" : "HS.png");
    },
    alt(statut) {
      return  "Déclarer l'équipement " + (statut == 0 ? "en panne" : "fonctionnel");
    },
    action(mesure) {
      if (mesure == null) return "";
      let cd = mesure.candela;
      if (cd < mesure.cdminimal) {
        return "Feu HS";
      } else if (cd > mesure.cdmaintenance) {
        return "Feu Ok";
      } else {
        return "Prévoir action";
      }
    },
    couleur(mesure) {
      if (mesure == null) return "green";
      let cd = mesure.candela;
      if (cd < mesure.cdminimal) {
        return "red";
      } else if (cd > mesure.cdmaintenance) {
        return "green";
      } else {
        return "orange";
      }
    },
    getActions() {
      let id = this.id;
      let idpiste = store.state.piste;
      let sql =
        "(SELECT a.id,a.dt AS dtaction,a.Sujet,a.remarque,f.id AS idfeu,f.nom AS nomfeu,c.strcode,a.solde" +
        ",a.idregulateur,r.nom AS nomregulateur" +
        ",a.idfonction,fct.libelle AS nomfonction" + 
        ",IF(a.typefeu=0,'',IF(typefeu=1,'Encastré','Elevé')) as encelv" +
        " FROM feu AS f" +
        " LEFT JOIN actionpiste AS a ON a.idfeu=f.id" +
        " LEFT JOIN maintenance AS m ON m.id=a.idmaintenance" +
        " LEFT JOIN cgbcommun.tableref AS c ON c.id=f.couleur" +
        " LEFT JOIN regulateur AS r ON r.id=a.idregulateur" +
        " LEFT JOIN cgbcommun.tableref AS fct ON fct.id=a.idfonction" +
        " WHERE m.idpiste=" + idpiste + " AND a.client=1 AND f.id=" + id + ")" +
        " UNION " +
        "(SELECT a.id,a.dt AS dtaction,a.Sujet,a.remarque,f.id AS idfeu,f.nom AS nomfeu,c.strcode,a.solde" +
        ",a.idregulateur,r.nom AS nomregulateur" +
        ",a.idfonction,fct.libelle AS nomfonction" +
        ",IF(a.typefeu=0,'',IF(typefeu=1,'Encastré','Elevé')) as encelv" +
        " FROM feu AS f" +
        " LEFT JOIN regulateur AS r ON r.id=f.idregulateur" +
        " LEFT JOIN actionpiste AS a ON a.idregulateur=r.id" +
        " LEFT JOIN maintenance AS m ON m.id=a.idmaintenance" +
        " LEFT JOIN cgbcommun.tableref AS c ON c.id=f.couleur" +
        " LEFT JOIN cgbcommun.tableref AS fct ON fct.id=a.idfonction" +
        " WHERE m.idpiste=" + idpiste + " AND a.client=1 AND f.id=" + id + " AND a.idfeu=0 AND r.id=a.idregulateur)" +
        " UNION " +
        " (SELECT a.id,a.dt AS dtaction,a.Sujet,a.remarque,f.id AS idfeu,f.nom AS nomfeu,c.strcode,a.solde" +
        ",a.idregulateur,r.nom AS nomregulateur" +
        ",a.idfonction,fct.libelle AS nomfonction" +
        ",IF(a.typefeu=0,'',IF(typefeu=1,'Encastré','Elevé')) as encelv" +
        " FROM feu AS f" +
        " LEFT JOIN regulateur AS r ON r.id=f.idregulateur" +
        " LEFT JOIN cgbcommun.tableref AS fct ON fct.id=f.idfonction" +
        " LEFT JOIN actionpiste AS a ON a.idfonction=fct.id" +
        " LEFT JOIN maintenance AS m ON m.id=a.idmaintenance" +
        " LEFT JOIN cgbcommun.tableref AS c ON c.id=f.couleur" +
        "  WHERE m.idpiste=" + idpiste + " AND a.client=1 AND f.id=" + id + " AND a.idfeu=0 AND a.idregulateur=0 AND fct.id=a.idfonction)" +
        " UNION " +
        " (SELECT a.id,a.dt AS dtaction,a.Sujet,a.remarque,f.id AS idfeu,f.nom AS nomfeu,c.strcode,a.solde" +
        ",a.idregulateur,r.nom AS nomregulateur" +
        ",a.idfonction,fct.libelle AS nomfonction" +
        ",IF(a.typefeu=0,'',IF(typefeu=1,'Encastré','Elevé')) as encelv" +
        " FROM feu AS f" +
        " LEFT JOIN regulateur AS r ON r.id=f.idregulateur" +
        " LEFT JOIN cgbcommun.tableref AS fct ON fct.id=f.idfonction" +
        " LEFT JOIN actionpiste AS a ON a.idfonction=fct.id" +
        " LEFT JOIN maintenance AS m ON m.id=a.idmaintenance" +
        " LEFT JOIN cgbcommun.tableref AS c ON c.id=f.couleur" +
        "  WHERE m.idpiste=" + idpiste + " AND a.client=1 AND f.id=" + id + " AND a.idfeu=0 AND a.idregulateur=0 AND a.idfonction=0 AND (f.feueleve+1)=a.typefeu)" +
        " ORDER BY dtaction ASC";

      selectApi(this, sql).then(
        (response) => {
          this.counta = response.data.count;
          this.actions = response.data.result;
        },
        (response) => {
        }
      );
    },
    getInterventions() {
      let id = this.id;
      let idpiste = store.state.piste;
      let sql =
        "(SELECT a.id,a.dt AS dtaction,a.Sujet,a.remarque,f.id AS idfeu,f.nom AS nomfeu,c.strcode" +
        ",a.idregulateur,r.nom AS nomregulateur" +
        ",a.idfonction,fct.libelle AS nomfonction" + 
        ",IF(a.typefeu=0,'',IF(typefeu=1,'Encastré','Elevé')) as encelv" +
        " FROM feu AS f" +
        " LEFT JOIN interpiste AS a ON a.idfeu=f.id" +
        " LEFT JOIN maintenance AS m ON m.id=a.idmaintenance" +
        " LEFT JOIN cgbcommun.tableref AS c ON c.id=f.couleur" +
        " LEFT JOIN regulateur AS r ON r.id=a.idregulateur" +
        " LEFT JOIN cgbcommun.tableref AS fct ON fct.id=a.idfonction" +
        " WHERE m.idpiste=" + idpiste + " AND a.client=1 AND f.id=" + id + ")" +
        " UNION " +
        "(SELECT a.id,a.dt AS dtaction,a.Sujet,a.remarque,f.id AS idfeu,f.nom AS nomfeu,c.strcode" +
        ",a.idregulateur,r.nom AS nomregulateur" +
        ",a.idfonction,fct.libelle AS nomfonction" +
        ",IF(a.typefeu=0,'',IF(typefeu=1,'Encastré','Elevé')) as encelv" +
        " FROM feu AS f" +
        " LEFT JOIN regulateur AS r ON r.id=f.idregulateur" +
        " LEFT JOIN interpiste AS a ON a.idregulateur=r.id" +
        " LEFT JOIN maintenance AS m ON m.id=a.idmaintenance" +
        " LEFT JOIN cgbcommun.tableref AS c ON c.id=f.couleur" +
        " LEFT JOIN cgbcommun.tableref AS fct ON fct.id=a.idfonction" +
        " WHERE m.idpiste=" + idpiste + " AND a.client=1 AND f.id=" + id + " AND a.idfeu=0 AND r.id=a.idregulateur)" +
        " UNION " +
        " (SELECT a.id,a.dt AS dtaction,a.Sujet,a.remarque,f.id AS idfeu,f.nom AS nomfeu,c.strcode" +
        ",a.idregulateur,r.nom AS nomregulateur" +
        ",a.idfonction,fct.libelle AS nomfonction" +
        ",IF(a.typefeu=0,'',IF(typefeu=1,'Encastré','Elevé')) as encelv" +
        " FROM feu AS f" +
        " LEFT JOIN regulateur AS r ON r.id=f.idregulateur" +
        " LEFT JOIN cgbcommun.tableref AS fct ON fct.id=f.idfonction" +
        " LEFT JOIN interpiste AS a ON a.idfonction=fct.id" +
        " LEFT JOIN maintenance AS m ON m.id=a.idmaintenance" +
        " LEFT JOIN cgbcommun.tableref AS c ON c.id=f.couleur" +
        "  WHERE m.idpiste=" + idpiste + " AND a.client=1 AND f.id=" + id + " AND a.idfeu=0 AND a.idregulateur=0 AND fct.id=a.idfonction)" +
        " UNION " +
        " (SELECT a.id,a.dt AS dtaction,a.Sujet,a.remarque,f.id AS idfeu,f.nom AS nomfeu,c.strcode" +
        ",a.idregulateur,r.nom AS nomregulateur" +
        ",a.idfonction,fct.libelle AS nomfonction" +
        ",IF(a.typefeu=0,'',IF(typefeu=1,'Encastré','Elevé')) as encelv" +
        " FROM feu AS f" +
        " LEFT JOIN regulateur AS r ON r.id=f.idregulateur" +
        " LEFT JOIN cgbcommun.tableref AS fct ON fct.id=f.idfonction" +
        " LEFT JOIN interpiste AS a ON a.idfonction=fct.id" +
        " LEFT JOIN maintenance AS m ON m.id=a.idmaintenance" +
        " LEFT JOIN cgbcommun.tableref AS c ON c.id=f.couleur" +
        "  WHERE m.idpiste=" + idpiste + " AND a.client=1 AND f.id=" + id + " AND a.idfeu=0 AND a.idregulateur=0 AND a.idfonction=0 AND (f.feueleve+1)=a.typefeu)" +
        " ORDER BY dtaction ASC";
      selectApi(this, sql).then(
        (response) => {
          this.counti = response.data.count;
          this.interventions = response.data.result;
        },
        (response) => {
        }
      );
    },
    getMesures(id) {
      let sql =
        "(SELECT m.candela,left(m.Dt,10) as dt10,fp.cdnominal,fp.cdminimal,fp.cdnominal - (fp.cdminimal / 2) as cdmaintenance,c.strcode,0 as nomes,f.idfonction,tf.libelle as typefeu,f.serigraphie,IF(tf.strcode2 is null,'FEU.png',tf.strcode2) as imgfeu" +
        " FROM mesurefeu as m" +
        " LEFT JOIN feu as f ON f.id=m.idfeu" +
        " LEFT JOIN cgbcommun.tableref AS c ON c.id=f.couleur" +
        " LEFT JOIN cgbcommun.tableref as eq ON eq.id=f.idfonction" +
        " LEFT JOIN cgbcommun.tableref as tf ON tf.id=f.idtype" +
        " LEFT JOIN fonctionpiste as fp ON fp.idequipement=eq.id" +
        " WHERE m.mesure=1 AND m.idfeu=" + id + ")" + 
        " UNION " + 
        "(SELECT m.candela2,left(m.Dt,10) as dt10,fp.cdnominal,fp.cdminimal,fp.cdnominal - (fp.cdminimal / 2) as cdmaintenance,c.strcode,1 as nomes,f.idfonction,tf.libelle as typefeu,f.serigraphie,IF(tf.strcode2 is null,'FEU.png',tf.strcode2) as imgfeu" +
        " FROM mesurefeu as m" +
        " LEFT JOIN feu as f ON f.id=m.idfeu" +
        " LEFT JOIN cgbcommun.tableref AS c ON c.id=f.couleur" +
        " LEFT JOIN cgbcommun.tableref as eq ON eq.id=f.idfonction" +
        " LEFT JOIN cgbcommun.tableref as tf ON tf.id=f.idtype" +
        " LEFT JOIN fonctionpiste as fp ON fp.idequipement=eq.id" +
        " WHERE m.mesure2=1 AND m.idfeu=" + id + ")"
        " ORDER BY dt10 DESC LIMIT 10";
      selectApi(this, sql).then(
        (response) => {
          this.mesures = response.data.result;
          this.countm = response.data.count;
          this.mesures.forEach((mesure) => {
            let attenuation = calcAttenuation(mesure.idfonction,mesure.nomes,mesure.strcode)
            mesure.cdminimal *= attenuation;
            mesure.cdnominal *= attenuation;
            mesure.cdmaintenance *= attenuation;
          });
        },
        (response) => {
          this.countm = 0;
          this.mesures = [];
        }
      );
    },
    refresh() {
      let idPiste = store.state.piste;
      let idFeu = this.id;
      let sql =
        "SELECT f.id as idfeu,f.nom as nomfeu,f.latitude as flat,f.longitude as flng,r.id as idreg,r.nom as nomreg,c.libelle as couleur," +
        "c.strcode,p.latitude as plat,p.longitude as plng,f.statut,r.depart," +
        "(SELECT count(*) FROM mesurefeu WHERE idfeu=f.id) as nblux,p.rotation,f.idfonction,tf.libelle as typefeu,f.serigraphie," +
        "IF(tf.strcode2 is null,'FEU.png',tf.strcode2) as imgfeu," +
        "IF(tf.strcode3 is null,'CGB.pdf',tf.strcode3) as docfeu," +
        "IF(f.feueleve=1,'Elevé','Encastré') as encelv" +
        " FROM feu AS f" +
        " LEFT JOIN regulateur AS r ON r.id=f.idregulateur" +
        " LEFT JOIN cgbcommun.tableref AS c ON c.id=f.couleur" +
        " LEFT JOIN cgbcommun.tableref as tf ON tf.id=f.idtype" +
        " LEFT JOIN piste as p ON p.id=f.idpiste" +
        " WHERE f.idpiste=" +
        idPiste +
        " AND f.id=" +
        idFeu;
      console.log(sql)
      selectApi(this, sql).then(
        (response) => {
          this.feu = [];
          if (response.data.count > 0) {
            this.feu = response.data.result[0];
            this.feu.imgfeu = getPrefix() + "/img/" + this.feu.imgfeu;
            this.getMesures(idFeu);
            this.getActions();
            this.getInterventions();
          }
        },
        (response) => {
        }
      );
    },
  },
  mounted() {
    if (store.state.typeUser == 0) {
      this.$router.push({ name: "login" });
    }
    this.refresh();
  },
};
</script>

<style lang="scss">
@import "src/assets/scss/design.scss";

#map {
  width: 100%;
  height: 700px;
  display: block;
}

.detail {
  padding: 10px;
}

.red {
  color: red;
}
.green {
  color: green;
}
.orange {
  color: orange;
}
</style>